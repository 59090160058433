:root {
  --color-primary: #6945B4;
  --color-purple500: #c5aef5;
  --color-secondary: #B0CE60;
  --color-white: #ffffff;
  --color-lightGrey: #f6f6f6;
  --color-lightPurple: #f1e9ff;
  --color-lightGreen: #f4fae5;
  --color-green500: #adbd84;
  --color-black: #000000;
  --color-red: #f54cec;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
