.main{
    background-color: #fff;
}
.active-nav {
    color: #6945B4
}

.nav-tab p {
    font-weight: 500;
}
