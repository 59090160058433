input:focus,textarea:focus {
  outline: none;
}

.np {
  /* height: 100vh; */
}

.np__header {
  height: 56px;
  width: 100%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.pbadge {
  border: "1px solid gray";
  padding: "4px 6px";
  border-radius: 60;
  align-items: "center";
  justify-content: "center";
  font-size: 10;
  color: "gray";
  background-color: "rgba(0,0,0,0.1)";
  white-space: "nowrap";
}